export default {
  night: 'Night',
  nights: 'Nights',
  week: 'Week',
  weeks: 'Weeks',
  'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
  'check-in': 'Check-in',
  'check-out': 'Check-out',
  'month-names': [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  tooltip: {
    halfDayCheckIn: 'Available CheckIn',
    halfDayCheckOut: 'Available CheckOut',
    saturdayToSaturday: 'Only Saturday to Saturday',
    sundayToSunday: 'Only Sunday to Sunday',
    minimumRequiredPeriod: '%{minNightInPeriod} %{night} minimum.',
  },
}
